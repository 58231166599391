import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { selectHome } from '../../app/homeSlice';
import { getFaceImagePath, imagePath } from '../../lib/pathes';
import { ActorBadge, ActorBadgeCategory, ActorBadgeCategoryValue, TenpoPointUnitType, TenpoPointUnitTypeValue } from '../../lib/api';
import { SloppyScalableText } from '../SloppyScalableText';

import { useTenpoPointUnitType } from 'hooks/useTenpoPointUnitType';

const width = 1036 / 3;
const height = 1908 / 3;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: width,
      height: height,
      backgroundColor: 'white',
    },
    layer: {
      position: 'absolute',
      width: width,
      height: height,
    },
    faceImage: {
      position: 'absolute',
      width: 190,
      height: 190,
      marginLeft: 'auto',
      marginRight: 'auto',
      objectFit: 'cover',
      borderRadius: '50%',
      left: 79,
      top: 205,
    },
    closeIcon: {
      position: 'absolute',
      width: 30,
      height: 30,
      top: 10,
      right: 0,
    },
    topTextBox: {
      position: 'absolute',
      width: width,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      letterSpacing: 0.6,
      top: 80,
    },
    titleText: {
      lineHeight: 1.3,
      fontSize: 21,
    },
    valueText: {
      lineHeight: 1.4,
      fontSize: 45,
    },
    unitText: {
      fontSize: 27,
      paddingLeft: 6,
    },
    bottomTextBox: {
      position: 'absolute',
      width: width,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: 27,
      fontWeight: 'bold',
      letterSpacing: 0.6,
      top: 472,
    },
  }),
);

function useTitleText(unitType: TenpoPointUnitType | undefined, category: ActorBadgeCategory | undefined): JSX.Element {
  const t = useTenpoPointUnitTypeLocal(unitType, 'ポイント', '小計', '');
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      return (
        <>
          ・店舗
          <br />
          ポイントランキング
        </>
      );
    case ActorBadgeCategoryValue.グループポイントランキング:
      return (
        <>
          ・グループ全体
          <br />
          ポイントランキング
        </>
      );
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      return (
        <>
          ・グループ全体
          <br />
          同伴ランキング
        </>
      );
    case ActorBadgeCategoryValue.ポイント:
      return (
        <>
          <br />
          {t}最高記録更新
        </>
      );
    case ActorBadgeCategoryValue.サンクスコイン:
      return (
        <>
          ・グループ全体
          <br />
          コイン獲得数
        </>
      );
    default:
      return <></>;
  }
}

function useUnitText(unitType: TenpoPointUnitType | undefined, category: ActorBadgeCategory | undefined): string {
  const t = useTenpoPointUnitTypeLocal(unitType, 'pt', '万円', '');
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      return '位';
    case ActorBadgeCategoryValue.グループポイントランキング:
      return '位';
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      return '位';
    case ActorBadgeCategoryValue.ポイント:
      return `${t}突破`;
    case ActorBadgeCategoryValue.サンクスコイン:
      return '個';
    default:
      return '';
  }
}

export const useTenpoPointUnitTypeLocal = function <T>(unitType: TenpoPointUnitType | undefined, pointValue: T, yenValue: T, defaultValue: T): T {
  const home = useSelector(selectHome);
  if (!home.loaded) return defaultValue;
  if (unitType === TenpoPointUnitTypeValue.ポイント) return pointValue;
  if (unitType === TenpoPointUnitTypeValue.小計) return yenValue;
  return defaultValue;
};

function useValueText(unitType: TenpoPointUnitType | undefined, category: ActorBadgeCategory | undefined, value:number | undefined): number | undefined{
  const 自己ベスト実績 = useTenpoPointUnitTypeLocal(unitType, (value ? Math.floor(value / 1000) : 0), (value ? Math.floor(value / 10000) : 0), 0);
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      return value;
    case ActorBadgeCategoryValue.グループポイントランキング:
      return value;
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      return value;
    case ActorBadgeCategoryValue.ポイント:
      return 自己ベスト実績;
    case ActorBadgeCategoryValue.サンクスコイン:
      return value;
    default:
      return 0;
  }
}

function renderPeriod(category: ActorBadgeCategory, period: number): string {
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
    case ActorBadgeCategoryValue.グループポイントランキング:
    case ActorBadgeCategoryValue.グループ同伴ランキング:
    case ActorBadgeCategoryValue.ポイント:
      return String(period);
    case ActorBadgeCategoryValue.サンクスコイン:
      return `${period}〜${period + 2}`;
    default:
      return '';
  }
}

const defaultImageUrl = imagePath('no_image.png');

type Props = {
  badge?: ActorBadge;
  handleOnClose: () => void;
};

export const BadgeDetailContents = (props: Props) => {
  const classes = useStyles();
  const { homeResponse } = useSelector(selectHome);
  const title = useTitleText(props.badge?.ポイント単位種別, props.badge?.category);
  const unit = useUnitText(props.badge?.ポイント単位種別, props.badge?.category);
  const badgeValue = useValueText(props.badge?.ポイント単位種別, props.badge?.category, props.badge?.value);

  const imageUrl = getFaceImagePath(homeResponse.actorFacePhotoId);
  const actorName = homeResponse.actorName ? homeResponse.actorName.replace(/[　|\s]/g, '') : '';
  const tenpoName =  props.badge ? props.badge.tenpoName : homeResponse.tenpoName;

  useEffect(() => {
    // if (!props.badge) return;
    // const gaUrlKeys = {
    //   [ActorBadgeCategoryValue.店舗ポイントランキング]: GAEventValue.HOME_BADGE_TPOINT,
    //   [ActorBadgeCategoryValue.グループポイントランキング]: GAEventValue.HOME_BADGE_GPOINT,
    //   [ActorBadgeCategoryValue.グループ同伴ランキング]: GAEventValue.HOME_BADGE_DOUHAN,
    //   [ActorBadgeCategoryValue.ポイント]: GAEventValue.HOME_BADGE_SAIKOU,
    //   [ActorBadgeCategoryValue.サンクスコイン]: GAEventValue.HOME_BADGE_THANKS,
    // }
    // reportGA(gaUrlKeys[props.badge.category], props.badge.monthName);
    // console.log("aa1", gaUrlKeys[props.badge.category], props.badge.monthName)
  }, []);

  return (
    <>
      {!!props.badge && (
        <div className={classes.container}>
          <div className={classes.layer}>
            <img
              className={classes.faceImage}
              src={imageUrl}
              onError={(e) => ((e.target as HTMLImageElement).src = defaultImageUrl)}
              alt="バッヂ"
            />
          </div>
          <img src={imagePath('commendation.png')} className={classes.layer} alt="表彰装飾" />
          <div className={classes.topTextBox}>
            <div className={classes.titleText}>
              {renderPeriod(props.badge.category, props.badge.period)}月期{title}
            </div>
            <div>
              <span className={classes.valueText}>{badgeValue}</span>
              <span className={classes.unitText}>{unit}</span>
            </div>
          </div>
          <div className={classes.bottomTextBox}>
            <div>
              <SloppyScalableText content={tenpoName} size={21} />
            </div>
            <div>
              <SloppyScalableText content={actorName} size={21} />
            </div>
          </div>
          <div className={classes.layer}>
            <div className={classes.closeIcon}>
              <CloseIcon onClick={props.handleOnClose} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
